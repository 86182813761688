<template>
  <div class="favq">
    <heand :tagnum="tagnum"></heand>

    <div class="enterprise clearfix">
      <div class="left">
        <ziyuannavleft />
      </div>
      <div class="right">
        <dl class="project_toptit">
          <dt>园区信息管理</dt>
          <dd>
            <span>基本信息</span>
            <span>土地信息</span>
            <span>楼宇信息</span>
            <span>标准化厂房（厂房）基本信息</span>
            <span>投资成本</span>
            <span>环境配套</span>
            <span>产业发展</span>
          </dd>
        </dl>
        <div class="project_main">
          <dl class="project_tit">
            <dt>基本信息</dt>
            <dd></dd>
          </dl>
          <ul class="proje_ul">
            <li>
              <strong>园区名称：</strong>
              <span>
                <!-- <el-input
                    v-model="name"
                    prop="name"
                    placeholder="请输入"
                  ></el-input> -->
                <el-input
                  v-model="name"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>园区级别：</strong>
              <span>
                <el-select v-model="level" disabled placeholder="请选择">
                  <el-option
                    v-for="item in invest"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </span>
            </li>
            <li>
              <strong>园区负责人：</strong>
              <span>
                <el-input
                  v-model="charge"
                  disabled
                  placeholder="请输入"
                ></el-input>
                <!-- <el-input v-model="charge" placeholder="请输入"></el-input> -->
              </span>
            </li>
            <li>
              <strong>联系电话：</strong>
              <span>
                <el-input
                  v-model="phone"
                  disabled
                  placeholder="请输入"
                ></el-input>
                <!-- <el-input v-model="phone" placeholder="请输入"></el-input> -->
              </span>
            </li>
            <li>
              <strong>园区类型：</strong>
              <span>
                <el-select v-model="cate" disabled placeholder="请选择">
                  <el-option
                    v-for="item in stage"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </span>
            </li>
            <li>
              <strong>所在地址：</strong>
              <span>
                <el-input
                  v-model="address"
                  disabled
                  placeholder="请输入"
                ></el-input>
                <!-- <el-input v-model="address" placeholder="请输入"></el-input> -->
              </span>
            </li>
            <li>
              <strong>产业集群：</strong>
              <span>
                <!-- <el-input v-model="cluster" placeholder="请输入"></el-input> -->
                <el-select
                  v-model="cluster"
                  multiple
                  disabled
                  placeholder="请选择"
                  @change="labelchange($event)"
                >
                  <!--  -->
                  <el-option
                    v-for="item in industry"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </span>
            </li>
            <li>
              <strong>占地面积：</strong>
              <span>
                <el-input
                  v-model="area"
                  disabled
                  placeholder="请输入"
                ></el-input
                >平方公里
              </span>
            </li>
            <li>
              <strong>规划面积：</strong>
              <span>
                <el-input
                  v-model="acreage"
                  disabled
                  placeholder="请输入"
                ></el-input
                >平方公里
              </span>
            </li>
            <li>
              <strong>GDP：</strong>
              <span>
                <el-input v-model="gdp" disabled placeholder="请输入" />亿元
              </span>
            </li>
          </ul>
          <dl class="project_tit">
            <dt>土地信息</dt>
          </dl>
          <ul class="proje_ul">
            <li>
              <strong>地块位置编号：</strong>
              <span>
                <el-input
                  v-model="land_code"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>

            <li>
              <strong>土地性质：</strong>
              <span>
                <el-input
                  v-model="land_property"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>

            <li>
              <strong>土地范围：</strong>
              <span>
                <el-input
                  v-model="land_range"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>

            <li>
              <strong>土地面积：</strong>
              <span>
                <el-input
                  v-model="land_size"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>

            <li>
              <strong>产业诉求：</strong>
              <span>
                <el-input
                  v-model="indu_appeal"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
          </ul>
          <dl class="project_tit">
            <dt>楼宇信息</dt>
          </dl>
          <ul class="proje_ul">
            <li>
              <strong>楼宇名称：</strong>
              <span>
                <el-input
                  v-model="build_name"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>楼宇位置：</strong>
              <span>
                <el-input
                  v-model="build_add"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>楼宇面积：</strong>
              <span>
                <el-input
                  v-model="build_size"
                  disabled
                  placeholder="请输入"
                ></el-input
                >平方公里
              </span>
            </li>
            <li>
              <strong>产业诉求：</strong>
              <span>
                <el-input
                  v-model="build_appeal"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
          </ul>
          <dl class="project_tit">
            <dt>标准化厂房（厂房）基本信息</dt>
          </dl>
          <ul class="proje_ul">
            <li>
              <strong>厂房位置：</strong>
              <span>
                <el-input
                  v-model="plant_add"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>厂房面积：</strong>
              <span>
                <el-input
                  v-model="plant_size"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>基本条件：</strong>
              <span>
                <el-input
                  v-model="plant_condition"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
          </ul>

          <dl class="project_tit">
            <dt>投资成本</dt>
            <span class="fold" v-show="!isFold" @click="isFold = !isFold"
              >显示</span
            >
            <span class="fold" v-show="isFold" @click="isFold = !isFold"
              >折叠</span
            >
          </dl>
          <ul class="proje_chengbenul" v-show="isFold">
            <li>
              <strong>居民用电（一档）：</strong>
              <span>
                <el-input
                  v-model="resident_ele_one"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/度
              </span>
            </li>
            <li>
              <strong>居民用电（二档）：</strong>
              <span>
                <el-input
                  v-model="resident_ele_two"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/度
              </span>
            </li>
            <li>
              <strong>居民用电（三档）：</strong>
              <span>
                <el-input
                  v-model="resident_ele_thr"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/度
              </span>
            </li>
            <li>
              <strong>商业用电（峰段）：</strong>
              <span>
                <el-input
                  v-model="comm_ele_one"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/度
              </span>
            </li>
            <li>
              <strong>商业用电（平段）：</strong>
              <span>
                <el-input
                  v-model="comm_ele_two"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/度
              </span>
            </li>
            <li>
              <strong>商业用电（谷段）：</strong>
              <span>
                <el-input
                  v-model="comm_ele_thr"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/度
              </span>
            </li>
            <li>
              <strong>工业电价（峰段）：</strong>
              <span>
                <el-input
                  v-model="indu_ele_one"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/度
              </span>
            </li>
            <li>
              <strong>工业电价（平段）：</strong>
              <span>
                <el-input
                  v-model="indu_ele_two"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/度
              </span>
            </li>
            <li>
              <strong>工业电价（谷段）：</strong>
              <span>
                <el-input
                  v-model="indu_ele_thr"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/度
              </span>
            </li>
            <li>
              <strong>居民用水（一档）：</strong>
              <span>
                <el-input
                  v-model="resident_water_one"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/吨
              </span>
            </li>
            <li>
              <strong>居民用水（二档）：</strong>
              <span>
                <el-input
                  v-model="resident_water_two"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/吨
              </span>
            </li>
            <li>
              <strong>居民用水（三档）：</strong>
              <span>
                <el-input
                  v-model="resident_water_thr"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/吨
              </span>
            </li>
            <li>
              <strong>商业用水：XX元/吨</strong>
              <span>
                <el-input
                  v-model="comm_water"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/吨
              </span>
            </li>
            <li>
              <strong>工业用水：</strong>
              <span>
                <el-input
                  v-model="indu_water"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/吨
              </span>
            </li>
            <li>
              <strong>特种用水：</strong>
              <span>
                <el-input
                  v-model="special_water"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/吨
              </span>
            </li>
            <li>
              <strong>居民用气（一档）：</strong>
              <span>
                <el-input
                  v-model="resident_natgas_one"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/m³
              </span>
            </li>
            <li>
              <strong>居民用气（二档） ：</strong>
              <span>
                <el-input
                  v-model="resident_natgas_two"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/m³
              </span>
            </li>
            <li>
              <strong>居民用气（三档） ：</strong>
              <span>
                <el-input
                  v-model="resident_natgas_thr"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/m³
              </span>
            </li>
            <li>
              <strong>污水处理：</strong>
              <span>
                <el-input
                  v-model="sewage"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/吨
              </span>
            </li>
            <li>
              <strong>最低工资标准：</strong>
              <span>
                <el-input
                  v-model="wagelevel"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/月
              </span>
            </li>
            <li>
              <strong>高层管理人员：</strong>
              <span>
                <el-input
                  v-model="highmag"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/月
              </span>
            </li>
            <li>
              <strong>普通员工：</strong>
              <span>
                <el-input
                  v-model="worker"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/月
              </span>
            </li>
            <li>
              <strong>中级管理人员：</strong>
              <span>
                <el-input
                  v-model="middlemag"
                  disabled
                  placeholder="请输入"
                ></el-input
                >元/月
              </span>
            </li>
          </ul>

          <dl class="project_tit" v-show="isFold">
            <dt>环境配套</dt>
          </dl>
          <ul class="proje_chengbenul proje_peitaoul" v-show="isFold">
            <li>
              <strong>距货运站距离：</strong>
              <span>
                <el-input
                  v-model="dis_freight"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>距港口距离：</strong>
              <span>
                <el-input
                  v-model="dis_port"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>距高铁距离：</strong>
              <span>
                <el-input
                  v-model="dis_rail"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>距机场距离：</strong>
              <span>
                <el-input
                  v-model="dis_air"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>公路运输：</strong>
              <span>
                <el-input
                  v-model="road_trans"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>铁路运输：</strong>
              <span>
                <el-input
                  v-model="rail_trans"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>园区生活配套：</strong>
              <span>
                <el-input
                  v-model="live_facility"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>百货商场：</strong>
              <span>
                <el-input
                  v-model="market"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>酒店商务：</strong>
              <span>
                <el-input
                  v-model="hotel_bus"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>医疗：</strong>
              <span>
                <el-input
                  v-model="medical"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>教育：</strong>
              <span>
                <el-input
                  v-model="education"
                  disabled
                  placeholder="请输入"
                ></el-input>
              </span>
            </li>
          </ul>

          <dl class="project_tit" v-show="isFold">
            <dt>产业发展</dt>
          </dl>
          <ul class="proje_chengbenul proje_fazhanul" v-show="isFold">
            <li>
              <strong>规模以上工业企业</strong>
              <span>
                <el-input
                  type="textarea"
                  :rows="2"
                  disabled
                  placeholder="请输入不少于20家规模以上企业"
                  v-model="induenterprise"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>创新资源</strong>
              <span>
                <el-input
                  type="textarea"
                  :rows="2"
                  disabled
                  placeholder="请输入不少于20家代表科研"
                  v-model="innovate"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>双创基地</strong>
              <span>
                <el-input
                  type="textarea"
                  :rows="2"
                  disabled
                  placeholder="请输入不少于20家代表科研"
                  v-model="base"
                ></el-input>
              </span>
            </li>
            <li>
              <strong>产业载体</strong>
              <span>
                <el-input
                  type="textarea"
                  :rows="2"
                  disabled
                  placeholder="请输入不少于20家代表科研"
                  v-model="carrier"
                ></el-input>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { detailGarden } from "@/api/index";
export default {
  data() {
    return {
      // 基本信息
      name: "", //  园区名称
      level: "", //  园区级别
      charge: "", //  园区负责人
      phone: "", //  联系电话
      cate: "", //  园区类型
      address: "", //  所在地
      cluster: [], //  产业集群
      area: "", //  占地面积
      acreage: "", //  规划面积
      gdp: "", //  gdp

      // 土地信息
      land_code: "", //   土地位置/编号
      land_property: "", //   土地性质
      land_range: "", //   土地范围
      land_size: "", //   土地面积/平方公里
      indu_appeal: "", //   产业诉求
      // 楼宇信息
      build_name: "", //   楼宇名称
      build_add: "", //   楼宇地址
      build_size: "", //   楼宇面积/平方公里
      build_appeal: "", //  楼宇诉求
      // 厂房信息
      plant_add: "", //   厂房位置
      plant_size: "", //   厂房面积
      plant_condition: "", //   厂房条件
      // 投资信息
      resident_ele_one: "", //居民用电（一档）（元/度）
      resident_ele_two: "", //居民用电（二档）（元/度）
      resident_ele_thr: "", //居民用电（三档）（元/度）
      comm_ele_one: "", //商业用电（峰段）（元/度）
      comm_ele_two: "", //商业用电（平段）（元/度）
      comm_ele_thr: "", //商业用电（谷段）（元/度）
      indu_ele_one: "", //工业用电（峰段）（元/度）
      indu_ele_two: "", //工业用电（平段）（元/度）
      indu_ele_thr: "", //工业用电（谷段）（元/度）
      resident_water_one: "", //居民用水（一档）（元/吨）
      resident_water_two: "", //居民用水（二档）（元/吨）
      resident_water_thr: "", //居民用水（三档）（元/吨）
      comm_water: "", //商业用水（元/吨）
      indu_water: "", //工业用水（元/吨）
      special_water: "", //特种用水（元/吨）
      resident_natgas_one: "", //居民用气（一档）（元/m³）
      resident_natgas_two: "", //居民用气（二档）（元/m³）
      resident_natgas_thr: "", //居民用气（三档）（元/m³）
      sewage: "", //污水处理（元/吨）
      wagelevel: "", //最低工资标准（元/月）
      worker: "", //普通员工（元/月）
      middlemag: "", //中级管理人员（元/月）
      highmag: "", //高层管理人员（元/月）
      // 环境配置
      dis_freight: "", //距离货运站距离
      dis_rail: "", //距离高铁站
      dis_port: "", //距离港口距离
      dis_air: "", //距离机场距离
      road_trans: "", //公路运输
      rail_trans: "", //铁路运输
      live_facility: "", //园区生活配套
      market: "", //百货商场
      hotel_bus: "", //酒店商务
      medical: "", //  医疗机构
      education: "", //  学校教育
      // 产业发展
      induenterprise: "", //  规模以上企业
      innovate: "", //  # 科研机构
      base: "", //  # 双创基地
      carrier: "", //  # 产业载体
      // 折叠显示
      isFold: false,
      token: "",
      tagnum: "7",
      industry: [],
      invest: [],
      stage: [],
      id: "",
    };
  },
  created() {
    this.token = localStorage.getItem("login");
    this.id = this.$route.query.id;
    this.getdetailGarden();
  },
  methods: {
    //获取数据
    async getdetailGarden() {
      const res = await detailGarden({
        id: this.id,
      });
      this.name = res.name;
      this.level = res.level;
      this.charge = res.charge;
      this.phone = res.phone;
      this.cate = res.cate;
      this.address = res.address;
      this.cluster = res.cluster.split(",");
      this.area = res.area;
      this.acreage = res.acreage;
      this.gdp = res.gdp;
      this.land_code = res.land_code;
      this.land_property = res.land_property;
      this.land_range = res.land_range;
      this.land_size = res.land_size;
      this.indu_appeal = res.indu_appeal;
      this.build_name = res.build_name;
      this.build_add = res.build_add;
      this.build_size = res.build_size;
      this.build_appeal = res.build_appeal;
      this.plant_add = res.plant_add;
      this.plant_size = res.plant_size;
      this.plant_condition = res.plant_condition;
      this.resident_ele_one = res.resident_ele_one;
      this.resident_ele_two = res.resident_ele_two;
      this.resident_ele_thr = res.resident_ele_thr;
      this.comm_ele_one = res.comm_ele_one;
      this.comm_ele_two = res.comm_ele_two;
      this.comm_ele_thr = res.comm_ele_thr;
      this.indu_ele_one = res.indu_ele_one;
      this.indu_ele_two = res.indu_ele_two;
      this.indu_ele_thr = res.indu_ele_thr;
      this.resident_water_one = res.resident_water_one;
      this.resident_water_two = res.resident_water_two;
      this.resident_water_thr = res.resident_water_thr;
      this.comm_water = res.comm_water;
      this.indu_water = res.indu_water;
      this.special_water = res.special_water;
      this.resident_natgas_one = res.resident_natgas_one;
      this.resident_natgas_two = res.resident_natgas_two;
      this.resident_natgas_thr = res.resident_natgas_thr;
      this.sewage = res.sewage;
      this.wagelevel = res.wagelevel;
      this.worker = res.worker;
      this.middlemag = res.middlemag;
      this.highmag = res.highmag;
      this.dis_freight = res.dis_freight;
      this.dis_rail = res.dis_rail;
      this.dis_port = res.dis_port;
      this.dis_air = res.dis_air;
      this.road_trans = res.road_trans;
      this.rail_trans = res.rail_trans;
      this.live_facility = res.live_facility;
      this.market = res.market;
      this.hotel_bus = res.hotel_bus;
      this.medical = res.medical;
      this.education = res.education;
      this.induenterprise = res.induenterprise;
      this.innovate = res.innovate;
      this.base = res.base;
      this.carrier = res.carrier;
    },
  },
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  background: rgb(243, 243, 243);
  padding-bottom: 30px;

  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  margin: 1% 0;
}
.right {
  width: calc(100% - 256px - 12px);
  float: right;

  .project_toptit {
    background: #fff;
    padding: 10px 20px;
    overflow: hidden;
    zoom: 1;
    width: calc(100% - 256px - 70px);
    margin-bottom: 10px;
    position: fixed;
    z-index: 999;
    dt {
      float: left;
      font-size: 16px;
      color: #444;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      margin-right: 20px;
    }
    dd {
      float: left;

      span {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        text-align: center;
        margin: 0 20px;
      }
    }
  }

  .proje_ul {
    background: #f3f6fb;
    padding: 20px 30px;
    overflow: hidden;
    zoom: 1;
    border: 1px solid #eee;
    margin-bottom: 15px;
    li {
      width: 50%;
      float: left;
      padding-right: 1%;
      font-size: 14px;
      text-align: left;
      line-height: 34px;
      margin-bottom: 10px;

      strong {
        font-weight: normal;
        display: block;
        width: 115px;
        float: left;
      }
      span {
        display: block;
        float: left;
        width: 68%;
        position: relative;
      }

      /deep/.el-input {
        width: 77%;
        min-height: 32px;
        line-height: 32px;
        margin-right: 5px;

        input {
          height: 32px;
          line-height: 32px;
          border-radius: 0;
          color: #333;
        }
        ::-webkit-input-disabled placeholder {
          color: #333;
        }
      }

      /deep/.el-input__icon {
        line-height: 32px;
      }

      /deep/.el-select {
        min-height: 30px;
        input {
          width: 100%;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
        }
      }
    }
    .qy_area {
      width: 100%;
      span {
        width: 78%;
      }
    }

    .qy_names {
      width: 100%;
      float: none;
      height: 34px;
      /deep/.el-input {
        width: 300px;
      }
    }
  }

  .proje_chengbenul {
    background: #f3f6fb;
    padding: 20px 30px;
    overflow: hidden;
    zoom: 1;
    border: 1px solid #eee;
    margin-bottom: 15px;
    li {
      width: 32%;
      float: left;
      padding-right: 1%;
      font-size: 14px;
      text-align: left;
      line-height: 34px;
      margin-bottom: 10px;

      strong {
        font-weight: normal;
        display: block;
        width: 135px;
        float: left;
      }
      span {
        display: block;
        float: left;
        width: 61%;
        position: relative;
      }

      /deep/.el-input {
        width: 78%;
        height: 32px;
        line-height: 32px;
        margin-right: 5px;

        input {
          height: 32px;
          line-height: 32px;
          border-radius: 0;
          color: #333;
        }
        ::-webkit-input-disabled placeholder {
          color: #333;
        }
      }

      /deep/.el-input__icon {
        line-height: 32px;
      }

      /deep/.el-select {
        height: 30px;
        input {
          width: 100%;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
        }
      }
    }
  }

  .proje_peitaoul {
    li {
      width: 100%;
      float: none;
      overflow: hidden;
      zoom: 1;

      strong {
        font-weight: normal;
        display: block;
        width: 105px;
      }

      span {
        width: 88%;
      }
      /deep/.el-input {
        width: 100%;
      }
    }
  }

  .proje_fazhanul {
    li {
      width: 100%;
      float: none;
      overflow: hidden;
      zoom: 1;

      strong {
        font-weight: normal;
        display: block;
        width: 125px;
      }

      span {
        width: 88%;
      }
      /deep/.el-input {
        width: 100%;
      }
    }
  }

  .project_main {
    background: #fff;
    padding: 0 20px 10px 20px;
    overflow: hidden;
    zoom: 1;
    margin-top: 70px;
    .pre_xtit {
      font-size: 14px;
      text-align: left;
      line-height: 28px;
      margin-left: 12px;
      font-weight: normal;
    }
    .pre_xtit::before {
      content: " ";
      display: block;
      float: left;
      width: 6px;
      height: 6px;
      margin-right: 8px;
      margin-top: 10px;
      background: #333;
    }

    .project_area {
      text-align: left;
      font-size: 14px;
      height: 100px;
      line-height: 28px;
      margin-left: 15px;
      margin-bottom: 10px;

      /deep/.el-textarea__inner {
        height: 100px;
        padding: 12px;
      }
      /deep/.el-textarea__inner:focus {
        border-color: #fe6a00;
      }
    }

    .project_tables {
      font-size: 14px;
      line-height: 44px;
      margin-bottom: 20px;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 2%;
      }
      .chengzi_btn {
        color: #fe6a00;
      }
      .lvzi_btn {
        color: #2cb54c;
      }
      .zizi_btn {
        color: #135de6;
      }
      .redzi_btn {
        color: #e51313;
      }
      .bluezi_btn {
        color: #0ebbf3;
      }
      .huangzi_btn {
        color: #e69554;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }

    .pro_btns {
      display: flex;
      justify-content: center;
    }
    .chengse_btn {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      background: #fe6a00;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      margin: 1% 5px;
      padding: 0 20px;
    }
    .chengse_kong {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      border: 1px solid #fe6a00;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      color: #fe6a00;
      margin: 1% 5px;
      padding: 0 20px;
      background-color: #fff;
      cursor: pointer;
    }
  }

  .project_tit {
    overflow: hidden;
    zoom: 1;
    margin: 25px 0 6px 0;

    dt {
      float: left;
      font-weight: bold;
      font-size: 16px;
      color: #fe6a00;
      height: 30px;
      line-height: 30px;
    }

    dt:before {
      content: " ";
      padding: 0 3px 0 0;
      margin-right: 10px;
      background: #fe6a00;
    }

    dd {
      float: right;
      display: flex;
    }
  }

  .project_tit2 {
    dd {
      float: left;
      margin-left: 30px;
      width: 80%;
      text-align: left;
    }
  }
  /deep/.el-button--primary {
    background-color: #fe6a00;
    border-color: #fe6a00;
  }

  .chengse_btn {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    background: #fe6a00;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    margin: 1% 5px;
    padding: 0 20px;
  }
  .chengse_kong {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    border: 1px solid #fe6a00;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    color: #fe6a00;
    margin: 1% 5px;
    padding: 0 20px;
  }

  //上传图片
  /deep/.el-upload-list--picture {
    width: 100%;
  }
  /deep/.el-upload-list__item {
    width: 148px;
    // height: 215px;
    height: auto;
    // padding: 30px;
    // background: #eee;
    border: none;
    float: left;
  }
  /deep/.el-upload-list__item-thumbnail {
    width: 100%;
    // height: 168px;
    margin: 0;
  }
  /deep/.el-upload-list__item-name {
    display: none;
  }

  /deep/.el-textarea__inner {
    width: 100%;
    height: 70px;
    padding: 12px;
    border-radius: 0;
  }
  /deep/.el-textarea__inner:focus {
    border-color: #fe6a00;
  }
  /deep/.el-tag {
    font-size: 9px;
  }
  /deep/.el-tag.el-tag--info {
  }
  /deep/.el-tag--small {
    height: 20px;
    line-height: 20px;
  }
  /deep/.el-select__tags {
    top: 6%;
    transform: none;
  }
}
.pup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
// .scale-in {
//     animation: scaleIn 0.25s both;
// }

// .scale-out {
//     animation: scaleOut 0.25s both;
//     display: none;
// }
/deep/.el-upload-list__item-name,
/deep/.el-upload-list {
  display: none !important;
}
#shpro /deep/.el-upload-list__item-name,
#shpro /deep/.el-upload-list {
  display: block !important;
}
#shpro2 /deep/.el-upload-list__item-name,
#shpro2 /deep/.el-upload-list {
  display: block !important;
}
/deep/ .el-icon-document:before {
  content: "";
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.project_tit {
  position: relative;
}
.fold {
  position: absolute;
  right: 10px;
  top: 0;
  color: #fe6a00;
  font-size: 16px;
  cursor: pointer;
}
.required::before {
  content: "*";
  margin-right: 5px;
  font-size: 20px;
  vertical-align: bottom;
  text-align: center;
  color: #fe6a00;
}
</style>
